import React from 'react';
import { 
  FireIcon, 
  BoltIcon, 
  CubeIcon,
  CheckCircleIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';
import Transition from '../components/Transition';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/services';
import { translations as homeTranslations } from '../translations/home';
import SEO from '../components/SEO';
import Partners from '../components/Partners';

const Services = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const tHome = homeTranslations[language];

  const seoData = {
    hr: {
      title: 'Microplet - Usluge laserskog rezanja metala i obrade plastike',
      description: 'Ponuda usluga: lasersko rezanje metala, izrada alata, obrada plastike, savijanje lima i CNC rezanje. Brza izrada i povoljne cijene.',
      keywords: 'Alatnica, Zavarivanje, Lasersko rezanje, Tokarenje, Brizganje plastike, Savijanje, Glodanje, Metalna obrada, Industrijska proizvodnja, CNC strojevi, Pleternica'
    },
    en: {
      title: 'Microplet - Metal Laser Cutting and Plastic Processing Services',
      description: 'Service offering: metal laser cutting, tool manufacturing, plastic processing, sheet metal bending, and CNC cutting. Fast delivery and competitive prices.',
      keywords: 'Tool shop, Welding, Laser cutting, Turning, Plastic injection, Bending, Milling, Metal processing, Industrial manufacturing, CNC machines, Pleternica'
    },
    de: {
      title: 'Microplet - Metall-Laserschneiden und Kunststoffverarbeitung',
      description: 'Leistungsangebot: Metall-Laserschneiden, Werkzeugherstellung, Kunststoffverarbeitung, Blechbiegen und CNC-Schneiden. Schnelle Lieferung und wettbewerbsfähige Preise.',
      keywords: 'Werkzeugbau, Schweißen, Laserschneiden, Drehen, Kunststoffspritzguss, Biegen, Fräsen, Metallbearbeitung, Industrielle Fertigung, CNC-Maschinen, Pleternica'
    }
  };

  return (
    <>
      <SEO {...seoData[language]} />
      <div className="min-h-screen bg-dark-900 text-white relative overflow-hidden">
        {/* Background Effects */}
        <div className="fixed inset-0 z-0">
          {/* Animated gradient circles */}
          <div className="absolute top-0 right-0 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] rounded-full animate-pulse"></div>
          <div className="absolute bottom-0 left-0 w-[1040px] h-[1040px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.03)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.08)_0%,transparent_70%)] rounded-full animate-pulse delay-1000"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.05)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.1)_0%,transparent_70%)] rounded-full animate-pulse delay-500"></div>
          
          {/* Animated gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-dark-900/50 to-dark-900 md:via-dark-900/30 animate-pulse"></div>
        </div>

        {/* Content wrapper with z-index to appear above background */}
        <div className="relative z-10">
          {/* Hero Section */}
          <section className="relative pt-60 pb-52 overflow-hidden">
            <div className="container mx-auto px-4">
              <div className="max-w-4xl mx-auto text-center">
                <Transition
                  show={true}
                  enter="transition-all duration-1000"
                  enterFrom="opacity-0 transform translate-y-8"
                  enterTo="opacity-100 transform translate-y-0"
                >
                  <h1 className="text-4xl md:text-6xl font-bold mb-6">
                    <span className="block pb-2 text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">
                      {t.hero.title}
                    </span>
                  </h1>
                  <p className="text-xl text-gray-400 mb-8">
                    {t.hero.description}
                  </p>
                </Transition>
              </div>
            </div>
          </section>

          {/* Main Services Section */}
          <section className="py-20">
            <div className="container mx-auto px-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Service 1 - Lasersko Rezanje */}
                <Transition delay={0.2}>
                  <div className="group h-full bg-[#0a0a0b] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:scale-[1.02]">
                    <div className="h-16 w-16 rounded-full bg-white/10 flex items-center justify-center mb-8">
                      <FireIcon className="h-10 w-10 text-white" />
                    </div>
                    <h3 className="text-2xl font-semibold text-white mb-4">
                      {tHome.services.metal.title}
                    </h3>
                    <p className="text-gray-400 mb-6">
                      {tHome.services.metal.description}
                    </p>
                    <p className="text-sm text-gray-500">
                      {tHome.services.metal.details}
                    </p>
                    <a href="/contact" className="inline-flex items-center mt-8 text-laser hover:text-[#FF8A3C] transition-colors duration-300">
                      <span>{t.services.cta}</span>
                      <ArrowRightIcon className="h-5 w-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    </a>
                  </div>
                </Transition>

                {/* Service 2 - Proizvodi od Metala i Plastike */}
                <Transition delay={0.3}>
                  <div className="group h-full bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 border border-laser/20 hover:border-laser/30 transition-all duration-500 hover:scale-[1.02]">
                    <div className="h-16 w-16 rounded-full bg-dark-900/10 flex items-center justify-center mb-8">
                      <BoltIcon className="h-10 w-10 text-dark-900" />
                    </div>
                    <h3 className="text-2xl font-semibold text-dark-900 mb-4">
                      {tHome.services.laser.title}
                    </h3>
                    <p className="text-dark-900 mb-6">
                      {tHome.services.laser.description}
                    </p>
                    <p className="text-sm text-dark-900">
                      {tHome.services.laser.details}
                    </p>
                    <a href="/contact" className="inline-flex items-center mt-8 text-dark-900 hover:text-laser transition-colors duration-300">
                      <span>{t.services.cta}</span>
                      <ArrowRightIcon className="h-5 w-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    </a>
                  </div>
                </Transition>

                {/* Service 3 - Alati i Kalupi */}
                <Transition delay={0.4}>
                  <div className="group h-full bg-[#0a0a0b] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:scale-[1.02]">
                    <div className="h-16 w-16 rounded-full bg-white/10 flex items-center justify-center mb-8">
                      <CubeIcon className="h-10 w-10 text-white" />
                    </div>
                    <h3 className="text-2xl font-semibold text-white mb-4">
                      {tHome.services.tools.title}
                    </h3>
                    <p className="text-gray-400 mb-6">
                      {tHome.services.tools.description}
                    </p>
                    <p className="text-sm text-gray-500">
                      {tHome.services.tools.details}
                    </p>
                    <a href="/contact" className="inline-flex items-center mt-8 text-laser hover:text-[#FF8A3C] transition-colors duration-300">
                      <span>{t.services.cta}</span>
                      <ArrowRightIcon className="h-5 w-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    </a>
                  </div>
                </Transition>
              </div>
            </div>
          </section>

          <Partners />

          {/* Process Section */}
          <section className="py-20 bg-[#0a0a0b]">
            <div className="container mx-auto px-4">
              <div className="max-w-4xl mx-auto text-center">
                <Transition delay={0.2}>
                  <div className="mb-4">
                    <span className="text-laser text-sm font-medium tracking-wider">
                      {t.process.title}
                    </span>
                  </div>
                </Transition>

                <Transition delay={0.3}>
                  <h2 className="text-3xl md:text-4xl font-bold mb-8">
                    <span className="text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">
                      {t.process.description}
                    </span>
                  </h2>
                </Transition>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
                  {t.process.steps.map((step, index) => (
                    <Transition key={index} delay={0.4 + index * 0.1}>
                      <div className="p-6">
                        <div className="h-12 w-12 rounded-full bg-laser/10 flex items-center justify-center mb-4 mx-auto">
                          <span className="text-laser font-bold">{index + 1}</span>
                        </div>
                        <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                        <p className="text-gray-400">
                          {step.description}
                        </p>
                      </div>
                    </Transition>
                  ))}
                </div>
              </div>
            </div>
          </section>

          {/* CTA Section */}
          <section className="py-20">
            <div className="container mx-auto px-4">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-3xl font-bold mb-6">{t.cta.title}</h2>
                <p className="text-xl text-gray-400 mb-8">
                  {t.cta.description}
                </p>
                <a
                  href="/contact"
                  className="inline-flex items-center px-8 py-3 bg-gradient-to-r from-laser to-[#FF8A3C] text-white text-lg rounded-full transition-all duration-300 hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] hover:scale-[1.02]"
                >
                  <span>{t.cta.button}</span>
                  <span className="ml-2 transform transition-transform duration-300 group-hover:translate-x-1">→</span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Services; 