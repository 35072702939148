import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { EnvelopeIcon, PhoneIcon, MapPinIcon } from '@heroicons/react/24/outline';
import Transition from '../components/Transition';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/contact';
import SEO from '../components/SEO';


const Contact = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [result, setResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setResult("Sending....");

    // Get form data
    const formData = new FormData(event.target);
    
    // Check honeypot
    if (formData.get('botcheck')) {
      setResult("Invalid submission");
      setIsSubmitting(false);
      return;
    }

    // Remove honeypot from form data
    formData.delete('botcheck');

    // Add access key from environment variables
    const accessKey = process.env.REACT_APP_WEB3FORMS_ACCESS_KEY;
    if (!accessKey) {
      throw new Error("Web3Forms access key is not configured");
    }
    formData.append("access_key", accessKey);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          access_key: '2476f180-7fc2-4db1-8d9c-d138921062c5',
          name: formData.get('name'),
          email: formData.get('email'),
          message: formData.get('message'),
          subject: 'New Contact Form Submission - Microplet',
        })
      });

      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        throw new Error(data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      setResult(error.message || "Failed to submit form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const seoData = {
    hr: {
      title: 'Microplet - Kontaktirajte nas za lasersko rezanje metala, izradu alata i obradu plastike',
      description: 'Specijalizirani smo za lasersko rezanje metala, tokarenje, glodanje i savijanje. Pružamo usluge brizganja plastike, zavarivanja i CNC obrade. Brza izrada i povoljne cijene.',
      keywords: 'Alatnica, Zavarivanje, Lasersko rezanje, Tokarenje, Brizganje plastike, Savijanje, Glodanje, Metalna obrada, Industrijska proizvodnja, CNC strojevi, Pleternica'
    },
    en: {
      title: 'Microplet - Contact Us for Metal Laser Cutting, Tool Manufacturing and Plastic Processing',
      description: 'We specialize in metal laser cutting, turning, milling, and bending. Offering plastic injection, welding, and CNC processing services. Fast delivery and competitive prices.',
      keywords: 'Tool shop, Welding, Laser cutting, Turning, Plastic injection, Bending, Milling, Metal processing, Industrial manufacturing, CNC machines, Pleternica'
    },
    de: {
      title: 'Microplet - Kontaktieren Sie uns für Metall-Laserschneiden, Werkzeugherstellung und Kunststoffverarbeitung',
      description: 'Wir sind spezialisiert auf Metall-Laserschneiden, Drehen, Fräsen und Biegen. Wir bieten Kunststoffspritzguss, Schweißen und CNC-Bearbeitung. Schnelle Lieferung und wettbewerbsfähige Preise.',
      keywords: 'Werkzeugbau, Schweißen, Laserschneiden, Drehen, Kunststoffspritzguss, Biegen, Fräsen, Metallbearbeitung, Industrielle Fertigung, CNC-Maschinen, Pleternica'
    }
  };

  return (
    <>
      <SEO {...seoData[language]} />
      <div className="min-h-screen bg-dark-900 text-white relative overflow-hidden">
        {/* Background Effects */}
        <div className="fixed inset-0 z-0">
          {/* Animated gradient circles */}
          <div className="absolute top-0 right-0 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.12)_0%,transparent_70%)] rounded-full animate-pulse"></div>
          <div className="absolute bottom-0 left-0 w-[1040px] h-[1040px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.03)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.08)_0%,transparent_70%)] rounded-full animate-pulse delay-1000"></div>
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[840px] h-[840px] bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.05)_0%,transparent_70%)] md:bg-[radial-gradient(circle_at_center,rgba(255,94,7,0.1)_0%,transparent_70%)] rounded-full animate-pulse delay-500"></div>
          
          {/* Animated gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-dark-900/50 to-dark-900 md:via-dark-900/30 animate-pulse"></div>
        </div>

        {/* Content wrapper with z-index to appear above background */}
        <div className="relative z-10">
          {/* Hero Section */}
          <section className="relative pt-60 pb-52 overflow-hidden">
            <div className="container mx-auto px-4">
              <div className="max-w-4xl mx-auto text-center">
                <Transition
                  show={true}
                  enter="transition-all duration-1000"
                  enterFrom="opacity-0 transform translate-y-8"
                  enterTo="opacity-100 transform translate-y-0"
                >
                  <h1 className="text-4xl md:text-6xl font-bold mb-6">
                    <span className="block pb-2 text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">
                      {t.hero.title}
                    </span>
                  </h1>
                  <p className="text-xl text-gray-400 mb-8">
                    {t.hero.description}
                  </p>
                </Transition>
              </div>
            </div>
          </section>

          {/* Contact Form Section */}
          <section className="py-20">
            <div className="container mx-auto px-4">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
                {/* Contact Information */}
                <div className="space-y-8">
                  <Transition delay={0.2}>
                    <div className="bg-[#0a0a0b] p-5 rounded-3xl">
                      <h2 className="text-2xl font-semibold mb-6">
                        {t.info.title}
                      </h2>
                      <div className="space-y-6">
                        <div className="flex items-start gap-4">
                          <div className="h-12 w-12 rounded-full bg-laser/10 flex items-center justify-center flex-shrink-0">
                            <EnvelopeIcon className="h-6 w-6 text-laser" />
                          </div>
                          <div>
                            <h3 className="text-lg font-medium mb-1">
                              {t.info.email}
                            </h3>
                            <p className="text-gray-400"><a href="mailto:microplet@gmail.com" className="hover:text-laser transition-colors">microplet@gmail.com</a></p>
                            <p className="text-gray-400"><a href="mailto:info@microplet.hr" className="hover:text-laser transition-colors">info@microplet.hr</a></p>
                          </div>
                        </div>
                        <div className="flex items-start gap-4">
                          <div className="h-12 w-12 rounded-full bg-laser/10 flex items-center justify-center flex-shrink-0">
                            <MapPinIcon className="h-6 w-6 text-laser" />
                          </div>
                          <div>
                            <h3 className="text-lg font-medium mb-1">
                              {t.info.address.title}
                            </h3>
                            <p className="text-gray-400">
                              <a href="https://maps.app.goo.gl/xMWL3HUNUsPXKqLM9" target="_blank" rel="noopener noreferrer" className="hover:text-laser transition-colors">
                                {t.info.address.street}, {t.info.address.city}, {t.info.address.country}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>

                  <Transition delay={0.3}>
                    <div className="bg-[#0a0a0b] p-5 rounded-3xl">
                      <h2 className="text-2xl font-semibold mb-6">
                        {t.info.hours.title}
                      </h2>
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <span className="text-gray-400">
                            {t.info.hours.weekdays}
                          </span>
                          <span className="text-white">07:00 - 15:00</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-gray-400">{t.info.hours.saturday} - {t.info.hours.sunday}</span>
                          <span className="text-white">Zatvoreno</span>
                        </div>
                      </div>
                    </div>
                  </Transition>

                  {/* Contact Persons */}
                  <Transition delay={0.4}>
                    <div className="bg-[#0a0a0b] p-5 rounded-3xl">
                      <h2 className="text-2xl font-semibold mb-6">
                        {t.info.persons.title}
                      </h2>
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <span className="text-gray-400">{t.info.persons.director}</span>
                          <div className="text-right">
                            <div className="text-white">Anto Barbarić</div>
                            <a href="tel:+38598256005" className="text-laser hover:text-[#FF8A3C] transition-colors">098/256-005</a>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-gray-400">{t.info.persons.accounting}</span>
                          <div className="text-right">
                            <div className="text-white">Nevenko Šarić</div>
                            <a href="tel:+385992511701" className="text-laser hover:text-[#FF8A3C] transition-colors">099/251-1701</a>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-gray-400">{t.info.persons.procurement}</span>
                          <div className="text-right">
                            <div className="text-white">Josipa Barbarić</div>
                            <a href="tel:+38598415444" className="text-laser hover:text-[#FF8A3C] transition-colors">098/415-444</a>
                          </div>
                        </div>
                        <div className="pt-4">
                          <h3 className="text-lg font-medium text-white mb-3">{t.info.persons.technical.title}</h3>
                          <div className="space-y-3">
                            <div className="flex justify-between items-center">
                              <span className="text-gray-400">{t.info.persons.technical.foreman}</span>
                              <div className="text-right">
                                <div className="text-white">Dominik Gabrek</div>
                                <a href="tel:+385992708400" className="text-laser hover:text-[#FF8A3C] transition-colors">099/270-8400</a>
                              </div>
                            </div>
                            <div className="flex justify-between items-center">
                              <span className="text-gray-400">{t.info.persons.technical.programmer}</span>
                              <div className="text-right">
                                <div className="text-white">Mario Mršić</div>
                                <a href="tel:+385992511702" className="text-laser hover:text-[#FF8A3C] transition-colors">099/251-1702</a>
                              </div>
                            </div>
                            <div className="flex justify-between items-center">
                              <span className="text-gray-400">{t.info.persons.technical.controller}</span>
                              <div className="text-right">
                                <div className="text-white">Tomislav Tvrdy</div>
                                <a href="tel:+385993802892" className="text-laser hover:text-[#FF8A3C] transition-colors">099/380-2892</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>

                {/* Contact Form */}
                <Transition delay={0.4}>
                  <div className="bg-[#0a0a0b] p-8 rounded-3xl">
                    <h2 className="text-2xl font-semibold mb-6">
                      {t.contact.title}
                    </h2>
                    <form className="space-y-6" onSubmit={onSubmit}>
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-400 mb-2">
                          {t.contact.form.name}
                        </label>
                        <input
                          type="text"
                          id="name"
                          required
                          name="name"
                          className="w-full px-4 py-3 bg-[#36383c] border border-[#36383c] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-laser focus:border-transparent"
                          placeholder={t.contact.form.placeholders.name}
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-400 mb-2">
                          {t.contact.form.email}
                        </label>
                        <input
                          type="email"
                          id="email"
                          required
                          name="email"
                          className="w-full px-4 py-3 bg-[#36383c] border border-[#36383c] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-laser focus:border-transparent"
                          placeholder={t.contact.form.placeholders.email}
                        />
                      </div>
                      <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-400 mb-2">
                          {t.contact.form.message}
                        </label>
                        <textarea
                          id="message"
                          required
                          name="message"
                          rows="4"
                          className="w-full px-4 py-3 bg-[#36383c] border border-[#36383c] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-laser focus:border-transparent"
                          placeholder={t.contact.form.placeholders.message}
                        ></textarea>
                      </div>
                      {/* Honeypot field - hidden from real users */}
                      <div className="absolute opacity-0 pointer-events-none">
                        <input
                          type="text"
                          name="botcheck"
                          tabIndex={-1}
                          autoComplete="off"
                          aria-hidden="true"
                          className="hidden"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`w-full px-6 py-3 bg-gradient-to-r from-laser to-[#FF8A3C] text-white rounded-lg font-medium hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] transition-all duration-300 ${
                          isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        {isSubmitting ? 'Sending...' : t.contact.form.button}
                      </button>
                      
                    </form>
                    {result && (
                      <div className={`mt-4 p-4 rounded-lg ${
                        result.includes("Successfully") 
                          ? "bg-green-500/10 text-green-500" 
                          : "bg-red-500/10 text-red-500"
                      }`}>
                        {result}
                      </div>
                    )}
                  </div>
                </Transition>
              </div>
            </div>
          </section>

          {/* Google Maps Section */}
          {/* <section className="py-20">
            <div className="container mx-auto px-4">
              <div className="bg-[#0a0a0b] p-5 rounded-3xl">
                <div className="w-full h-[400px] rounded-xl overflow-hidden">
                <iframe 
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4720.831391240972!2d17.80773379506615!3d45.287754130601854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475d9994544513bf%3A0x438e7ca97f4a6596!2sMICROPLET!5e0!3m2!1shr!2shr!4v1743604528209!5m2!1shr!2shr" 
                width="100%" 
                height="100%" 
                style={{ border: 0 }} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full h-full">
                </iframe>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
};

export default Contact; 