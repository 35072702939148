import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/home';
import { BeakerIcon, BoltIcon, CubeIcon, SparklesIcon, ArrowRightIcon, FireIcon } from '@heroicons/react/24/outline';
import Transition from '../components/Transition';
import MosaicGallery from '../components/MosaicGallery';
import SEO from '../components/SEO';
import Partners from '../components/Partners';

const Home = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [result, setResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setResult("Sending....");

    // Get form data
    const formData = new FormData(event.target);
    
    // Check honeypot
    if (formData.get('botcheck')) {
      setResult("Invalid submission");
      setIsSubmitting(false);
      return;
    }

    // Remove honeypot from form data
    formData.delete('botcheck');

    // Add access key from environment variables
    const accessKey = process.env.REACT_APP_WEB3FORMS_ACCESS_KEY;
    if (!accessKey) {
      throw new Error("Web3Forms access key is not configured");
    }
    formData.append("access_key", accessKey);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          access_key: '2476f180-7fc2-4db1-8d9c-d138921062c5',
          name: formData.get('name'),
          email: formData.get('email'),
          message: formData.get('message'),
          subject: 'New Contact Form Submission - Microplet',
        })
      });

      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        throw new Error(data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      setResult(error.message || "Failed to submit form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const seoData = {
    hr: {      
      title: 'Microplet - Lasersko rezanje metala, izrada alata i prerada plastike',
      description: 'Specijalizirani smo za lasersko rezanje metala, tokarenje, glodanje i savijanje. Pružamo usluge brizganja plastike, zavarivanja i CNC obrade. Brza izrada i povoljne cijene.',
      keywords: 'Alatnica, Zavarivanje, Lasersko rezanje, Tokarenje, Brizganje plastike, Savijanje, Glodanje, Metalna obrada, Industrijska proizvodnja, CNC strojevi, Pleternica'
    },
    en: {
      title: 'Microplet - Metal Laser Cutting, Tool Manufacturing and Plastic Processing',
      description: 'We specialize in metal laser cutting, turning, milling, and bending. Offering plastic injection, welding, and CNC processing services. Fast delivery and competitive prices.',
      keywords: 'Tool shop, Welding, Laser cutting, Turning, Plastic injection, Bending, Milling, Metal processing, Industrial manufacturing, CNC machines, Pleternica'
    },
    de: {
      title: 'Microplet - Metall-Laserschneiden, Werkzeugherstellung und Kunststoffverarbeitung',
      description: 'Wir sind spezialisiert auf Metall-Laserschneiden, Drehen, Fräsen und Biegen. Wir bieten Kunststoffspritzguss, Schweißen und CNC-Bearbeitung. Schnelle Lieferung und wettbewerbsfähige Preise.',
      keywords: 'Werkzeugbau, Schweißen, Laserschneiden, Drehen, Kunststoffspritzguss, Biegen, Fräsen, Metallbearbeitung, Industrielle Fertigung, CNC-Maschinen, Pleternica'
    }
  };

  const features = [
    {
      name: 'Preciznost i Kvaliteta',
      description: 'Laser rezanje s preciznošću do 0.1mm za savršene rezove i detalje.',
      icon: BeakerIcon,
    },
    {
      name: 'Brza Izrada',
      description: 'Brzinska proizvodnja s kratkim vremenima isporuke za vaše projekte.',
      icon: BoltIcon,
    },
    {
      name: 'Prilagodljiva Rješenja',
      description: 'Prilagodljive usluge za različite materijale i kompleksne projekte.',
      icon: CubeIcon,
    },
  ];

  return (
    <>
      <SEO {...seoData[language]} />
      <div className="bg-[#050506] min-h-screen">
        {/* Hero Section */}
        <div className="relative min-h-screen flex items-center">
          {/* Background Image with Overlay */}
          <div className="absolute inset-0">
            <img
              src="/images/laser.jpg"
              alt="Laser Cutting"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-dark-900/90 via-dark-900/70 to-dark-900/50" />
            <div className="absolute inset-0 bg-gradient-to-b from-dark-900/50 to-dark-900/90" />
          </div>

          {/* Content */}
          <div className="relative container mx-auto px-4 xl:max-w-[80%]">
            <div className="max-w-4xl mx-auto text-center">
              <Transition delay={0.2}>
                <div className="inline-flex items-center text-laser text-sm font-medium">
                  <SparklesIcon className="h-5 w-5 mr-2" />
                  <span>{t.hero.title}</span>
                </div>
              </Transition>

              <Transition delay={0.4}>
                <h1 className="text-[36px] md:text-[56px] font-[500] leading-[40px] md:leading-[64px] font-['Inter'] mt-6">
                  <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.hero.subtitle}</span>
                  <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text mt-2">{t.hero.description}</span>
                  <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text mt-2">{t.hero.description2}</span>
                </h1>
              </Transition>

              <Transition delay={0.8}>
                <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 justify-center mt-12 px-4 sm:px-0">
                  <Link
                    to="/contact"
                    className="group relative inline-flex w-full sm:w-auto items-center justify-center gap-2 px-6 sm:px-8 py-4 text-[16px] sm:text-lg font-medium rounded-full bg-gradient-to-r from-laser to-[#FF8A3C] text-white transition-all duration-500 shadow-[0_0_20px_rgba(255,94,7,0.25)] hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] hover:scale-[1.02]"
                  >
                    <span>{t.hero.cta}</span>
                    <span className="transform transition-transform duration-500 group-hover:translate-x-1">→</span>
                  </Link>
                  <Link
                    to="/how-it-works"
                    className="group relative inline-flex w-full sm:w-auto items-center justify-center gap-2 px-6 sm:px-8 py-4 text-[16px] sm:text-lg font-medium rounded-full text-white transition-all duration-500 overflow-hidden hover:scale-[1.02]"
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-white/5 via-white/10 to-white/5 bg-[length:200%_auto] bg-position-0 group-hover:bg-position-100 transition-all duration-500 border border-white/10 group-hover:border-white/20 rounded-full"></div>
                    <span className="relative z-10">{t.hero.secondaryCta}</span>
                    <span className="relative z-10 opacity-0 transform translate-x-full transition-all duration-500 group-hover:opacity-100 group-hover:translate-x-0">→</span>
                  </Link>
                </div>
              </Transition>
            </div>
          </div>
        </div>

        {/* Shared Background Effects Container for Features, Services, and Gallery */}
        <div className="relative">
          {/* Shared Background Effects */}
          <div className="absolute inset-0 pointer-events-none">
            {/* Main orange circles */}
            <div className="absolute top-[20%] right-[10%] w-[80px] md:w-[300px] lg:w-[600px] h-[80px] md:h-[300px] lg:h-[600px] bg-[radial-gradient(circle,rgba(255,94,7,0.15)_0%,rgba(255,94,7,0.05)_30%,transparent_70%)] blur-3xl rounded-full" />
            <div className="absolute top-[80%] left-[10%] w-[80px] md:w-[300px] lg:w-[600px] h-[80px] md:h-[300px] lg:h-[600px] bg-[radial-gradient(circle,rgba(255,94,7,0.15)_0%,rgba(255,94,7,0.05)_30%,transparent_70%)] blur-3xl rounded-full" />
            
            {/* Additional glow effects */}
            {/* <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(ellipse_at_center,rgba(255,94,7,0.02)_0%,transparent_60%)] md:bg-[radial-gradient(ellipse_at_center,rgba(255,94,7,0.03)_0%,transparent_60%)]" />
            <div className="absolute bottom-0 right-0 w-full h-full bg-[radial-gradient(circle_at_bottom_right,rgba(255,94,7,0.02)_0%,transparent_60%)] md:bg-[radial-gradient(circle_at_bottom_right,rgba(255,94,7,0.04)_0%,transparent_60%)]" /> */}
          </div>

          {/* Features Section */}
          <div className="relative py-32">
            <div className="relative container mx-auto px-4 xl:max-w-[80%]">
              <Transition delay={0.2}>
                <div className="mb-4">
                  <span className="text-laser text-sm font-medium tracking-wider">
                    {t.expertise1.title}
                  </span>
                </div>
              </Transition>

              <div className="flex flex-col lg:flex-row justify-between items-start mb-16">
                <Transition delay={0.3}>
                  <h2 className="text-left max-w-4xl">
                    <span className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter']">
                      {/* <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.expertise1.subtitle}</span> */}
                      <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.expertise1.quality}</span>
                    </span>
                  </h2>
                </Transition>

                <Transition delay={0.4}>
                  <p className="text-gray-400 text-lg mt-6 lg:mt-0 lg:text-right max-w-sm">
                    {t.expertise1.details}
                  </p>
                </Transition>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-24">
                {/* Feature 1 */}
                <Transition delay={0.5}>
                  <div className="group h-full bg-[#0a0a0b] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500">
                    <div className="h-16 w-16 rounded-full bg-white/10 flex items-center justify-center mb-8">
                      <FireIcon className="h-10 w-10 text-white" />
                    </div>
                    <h3 className="text-2xl font-semibold text-white mb-4">
                      {t.services.metal.title}
                    </h3>
                    <p className="text-gray-400 mb-6">
                      {t.services.metal.description}
                    </p>
                    <p className="text-sm text-gray-500">
                      {t.services.metal.details}
                    </p>
                  </div>
                </Transition>

                {/* Feature 2 */}
                <Transition delay={0.6}>
                  <div className="group h-full bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FF5E07_-135.71deg,#FF8A3C_3.39deg,#FF5E07_224.29deg,#FF8A3C_363.39deg)] backdrop-blur-sm rounded-3xl p-8 border border-laser/20 hover:border-laser/30 transition-all duration-500">
                    <div className="h-16 w-16 rounded-full bg-white/10 flex items-center justify-center mb-8">
                      <BoltIcon className="h-10 w-10 text-white" />
                    </div>
                    <h3 className="text-2xl font-semibold text-white mb-4">
                      {t.services.laser.title}
                    </h3>
                    <p className="text-gray-200 mb-6">
                      {t.services.laser.description}
                    </p>
                    <p className="text-sm text-gray-100">
                      {t.services.laser.details}
                    </p>
                  </div>
                </Transition>

                {/* Feature 3 */}
                <Transition delay={0.7}>
                  <div className="group h-full bg-[#0a0a0b] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500">
                    <div className="h-16 w-16 rounded-full bg-white/10 flex items-center justify-center mb-8">
                      <CubeIcon className="h-10 w-10 text-white" />
                    </div>
                    <h3 className="text-2xl font-semibold text-white mb-4">
                      {t.services.tools.title}
                    </h3>
                    <p className="text-gray-400 mb-6">
                      {t.services.tools.description}
                    </p>
                    <p className="text-sm text-gray-500">
                      {t.services.tools.details}
                    </p>
                  </div>
                </Transition>
              </div>
            </div>
          </div>

          {/* Services Overview Section */}
          <div className="relative py-32">
            <div className="relative container mx-auto px-4 xl:max-w-[80%]">
              <div className="text-center mb-16">
                <Transition delay={0.2}>
                  <span className="text-laser text-sm font-medium tracking-wider">
                    {t.solutions.title}
                  </span>
                </Transition>
                
                <Transition delay={0.3}>
                  <h2 className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter'] mt-6">
                    <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.solutions.subtitle}</span>
                    <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text mt-2">{t.solutions.description}</span>
                  </h2>
                </Transition>

                <Transition delay={0.4}>
                  <p className="text-gray-400 text-lg mt-6 max-w-3xl mx-auto">
                    {t.solutions.mainDescription}
                  </p>
                </Transition>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
                {/* Card 1 */}
                <Transition delay={0.5}>
                <div className="group relative bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:rotate-3 hover:-translate-y-2 hover:shadow-xl">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="h-12 w-12 rounded-full bg-[#36383c] flex items-center justify-center">
                        <BeakerIcon className="h-6 w-6 text-white" />
                      </div>
                      <h3 className="text-lg font-semibold text-white group-hover:text-dark-900 transition-colors duration-500">{t.solutions.items[0].title}</h3>
                    </div>
                    <p className="text-gray-400 text-sm group-hover:text-dark-900 transition-colors duration-500">
                      {t.solutions.items[0].description}
                    </p>
                  </div>
                </Transition>

                {/* Card 2 */}
                <Transition delay={0.6}>
                  <div className="group relative bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 rotate-3 -translate-y-2 shadow-xl hover:rotate-0 hover:translate-y-0">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="h-12 w-12 rounded-full bg-[#36383c] flex items-center justify-center">
                        <BoltIcon className="h-6 w-6 text-white" />
                      </div>
                      <h3 className="text-lg font-semibold text-dark-900">{t.solutions.items[1].title}</h3>
                    </div>
                    <p className="text-dark-900 text-sm">
                      {t.solutions.items[1].description}
                    </p>
                  </div>
                </Transition>

                {/* Card 3 */}
                <Transition delay={0.7}>
                  <div className="group relative bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:rotate-3 hover:-translate-y-2 hover:shadow-xl">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="h-12 w-12 rounded-full bg-[#36383c] flex items-center justify-center">
                        <CubeIcon className="h-6 w-6 text-white" />
                      </div>
                      <h3 className="text-lg font-semibold text-white group-hover:text-dark-900">{t.solutions.items[2].title}</h3>
                    </div>
                    <p className="text-gray-400 text-sm group-hover:text-dark-900">
                      {t.solutions.items[2].description}
                    </p>
                  </div>
                </Transition>

                {/* Card 4 */}
                <Transition delay={0.8}>
                  <div className="group relative bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:rotate-3 hover:-translate-y-2 hover:shadow-xl">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="h-12 w-12 rounded-full bg-[#36383c] flex items-center justify-center">
                        <SparklesIcon className="h-6 w-6 text-white" />
                      </div>
                      <h3 className="text-lg font-semibold text-white group-hover:text-dark-900">{t.solutions.items[3].title}</h3>
                    </div>
                    <p className="text-gray-400 text-sm group-hover:text-dark-900">
                      {t.solutions.items[3].description}
                    </p>
                  </div>
                </Transition>

                {/* Card 5 */}
                <Transition delay={0.9}>
                  <div className="group relative bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:rotate-3 hover:-translate-y-2 hover:shadow-xl">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="h-12 w-12 rounded-full bg-[#36383c] flex items-center justify-center">
                        <ArrowRightIcon className="h-6 w-6 text-white" />
                      </div>
                      <h3 className="text-lg font-semibold text-white group-hover:text-dark-900 transition-colors duration-500">{t.solutions.items[4].title}</h3>
                    </div>
                    <p className="text-gray-400 text-sm group-hover:text-dark-900 transition-colors duration-500">
                      {t.solutions.items[4].description}
                    </p>
                  </div>
                </Transition>

                {/* Card 6 */}
                <Transition delay={1.0}>
                  <div className="group relative bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] backdrop-blur-sm rounded-3xl p-8 transition-all duration-500 hover:rotate-3 hover:-translate-y-2 hover:shadow-xl">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="h-12 w-12 rounded-full bg-[#36383c] flex items-center justify-center">
                        <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                      </div>
                      <h3 className="text-lg font-semibold text-white group-hover:text-dark-900 transition-colors duration-500">{t.solutions.items[5].title}</h3>
                    </div>
                    <p className="text-gray-400 text-sm group-hover:text-dark-900 transition-colors duration-500">
                      {t.solutions.items[5].description}
                    </p>
                  </div>
                </Transition>
              </div>
            </div>
          </div>

          {/* Mosaic Gallery Section */}
          <MosaicGallery />


          {/* Core Features Section */}
          <div className="relative py-32">
            <div className="relative container mx-auto px-4 xl:max-w-[80%]">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
                {/* Left Column - Text */}
                <div>
                  <Transition delay={0.2}>
                    <div className="mb-4">
                      <span className="text-laser text-sm font-medium tracking-wider">
                        {t.expertise.title}
                      </span>
                    </div>
                  </Transition>

                  <Transition delay={0.3}>
                    <h2 className="text-left">
                      <span className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter']">
                        <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.expertise.subtitle}</span>
                      </span>
                    </h2>
                  </Transition>

                  <Transition delay={0.4}>
                    <p className="text-gray-400 text-lg mt-6 max-w-lg">
                      {t.expertise.details}
                    </p>
                  </Transition>

                  {/* Overlapping Images */}
                  <div className="relative mt-12">
                    {/* Mobile: Stack, Tablet: Side by Side, Desktop: Overlap */}
                    <div className="flex flex-col sm:flex-row lg:block gap-6 sm:gap-8">
                      <Transition delay={0.5}>
                        <div className="relative z-20 w-full sm:w-[280px] lg:w-[300px] h-[280px] sm:h-[320px] lg:h-[340px] rounded-3xl overflow-hidden shadow-2xl">
                          <img 
                            src="/images/Savijanje/s1.jpeg" 
                            alt="Proces Proizvodnje"
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent" />
                        </div>
                      </Transition>
                      
                      <Transition delay={0.6}>
                        <div className="relative lg:absolute lg:-bottom-[100px] lg:left-[180px] z-10 w-full sm:w-[280px] lg:w-[300px] h-[280px] sm:h-[320px] lg:h-[340px] rounded-3xl overflow-hidden shadow-2xl">
                          <img 
                            src="/images/Zavarivanje/v3.jpeg" 
                            alt="Industrijska Tehnologija"
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent" />
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>

                {/* Right Column - Cards */}
                <div className="space-y-8">
                  {/* Feature Card 1 */}
                  <Transition delay={0.5}>
                    <div className="bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] rounded-3xl p-8 transition-all duration-500 group">
                      <div className="flex items-start gap-4">
                        <span className="text-[56px] text-[#666666] font-medium group-hover:text-dark-900 transition-colors duration-500">01</span>
                        <div>
                          <h3 className="text-2xl font-semibold text-white group-hover:text-dark-900 transition-colors duration-500 mb-4">
                            {t.process.steps[0].title}
                          </h3>
                          <p className="text-gray-400 group-hover:text-dark-900 transition-colors duration-500">
                            {t.process.steps[0].description}
                          </p>
                          <Link to="/contact" className="inline-flex items-center text-white group-hover:text-dark-900 transition-colors duration-500 mt-6 group/link">
                            <span>{t.process.steps[0].cta}</span>
                            <span className="ml-2 transform transition-transform duration-300 group-hover/link:translate-x-1">→</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Transition>

                  {/* Feature Card 2 */}
                  <Transition delay={0.6}>
                    <div className="bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] rounded-3xl p-8 transition-all duration-500 group">
                      <div className="flex items-start gap-4">
                        <span className="text-[56px] text-dark-900 font-medium transition-colors duration-500">02</span>
                        <div>
                          <h3 className="text-2xl font-semibold text-dark-900 transition-colors duration-500 mb-4">
                            {t.process.steps[1].title}
                          </h3>
                          <p className="text-dark-900 transition-colors duration-500">
                            {t.process.steps[1].description}
                          </p>
                          <Link to="/contact" className="inline-flex items-center text-dark-900 transition-colors duration-500 mt-6 group/link">
                            <span>{t.process.steps[1].cta}</span>
                            <span className="ml-2 transform transition-transform duration-300 group-hover/link:translate-x-1">→</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Transition>

                  {/* Feature Card 3 */}
                  <Transition delay={0.7}>
                    <div className="bg-[#0a0a0b] hover:bg-[conic-gradient(from_140.06deg_at_56.99%_57.62%,#FFFFFF_-135.71deg,#A8A8A8_3.39deg,#FFFFFF_224.29deg,#A8A8A8_363.39deg)] rounded-3xl p-8 transition-all duration-500 group">
                      <div className="flex items-start gap-4">
                        <span className="text-[56px] text-[#666666] font-medium group-hover:text-dark-900 transition-colors duration-500">03</span>
                        <div>
                          <h3 className="text-2xl font-semibold text-white group-hover:text-dark-900 transition-colors duration-500 mb-4">
                            {t.process.steps[2].title}
                          </h3>
                          <p className="text-gray-400 group-hover:text-dark-900 transition-colors duration-500">
                            {t.process.steps[2].description}
                          </p>
                          <Link to="/contact" className="inline-flex items-center text-white group-hover:text-dark-900 transition-colors duration-500 mt-6 group/link">
                            <span>{t.process.steps[2].cta}</span>
                            <span className="ml-2 transform transition-transform duration-300 group-hover/link:translate-x-1">→</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        

        {/* Partners Section */}
        <Partners />

        {/* Contact Form Section */}
        <div className="relative py-32 bg-[#050506]">
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(ellipse_at_center,rgba(255,94,7,0.03)_0%,transparent_60%)] pointer-events-none" />
          </div>

          <div className="relative container mx-auto px-4 xl:max-w-[80%]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start">
              {/* Left Column - Text */}
              <div>
                <Transition delay={0.2}>
                  <div className="mb-4">
                    <span className="text-laser text-sm font-medium tracking-wider">
                      {t.contact.title}
                    </span>
                  </div>
                </Transition>

                <Transition delay={0.3}>
                  <h2 className="text-left">
                    <span className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter']">
                      <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.contact.title}</span>
                      {/* <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_54%,#36383c)] [-webkit-background-clip:text] bg-clip-text mt-2">{t.contact.subtitle}</span> */}
                    </span>
                  </h2>
                </Transition>

                <Transition delay={0.4}>
                  <p className="text-gray-400 text-lg mt-6">
                    {t.contact.description}
                  </p>
                </Transition>

                <Transition delay={0.5}>
                  <div className="mt-8 space-y-6">
                    <div className="flex items-center gap-4">
                      <div className="h-12 w-12 rounded-full bg-[#0a0a0b] flex items-center justify-center">
                        <svg className="h-6 w-6 text-laser" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                      </div>
                      <div>
                        <h3 className="text-white text-lg font-medium">{t.contact.info.email}</h3>
                        <p className="text-gray-400"><a href="mailto:microplet@gmail.com">microplet@gmail.com</a></p>
                        <p className="text-gray-400"><a href="mailto:info@microplet.hr">info@microplet.hr</a></p>
                      </div>
                    </div>
                    

                    <div className="flex items-center gap-4">
                      <div className="h-12 w-12 rounded-full bg-[#0a0a0b] flex items-center justify-center">
                        <svg className="h-6 w-6 text-laser" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </div>
                      <div>
                        <h3 className="text-white text-lg font-medium">{t.contact.info.address}</h3>
                        <p className="text-gray-400"><a href="https://maps.app.goo.gl/xMWL3HUNUsPXKqLM9" target="_blank" rel="noopener noreferrer">Ante Starčevića 22, Pleternica</a></p>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>

              {/* Right Column - Form */}
              <div>
                <Transition delay={0.4}>
                  <form className="space-y-6" onSubmit={onSubmit}>
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-400 mb-2">
                          {t.contact.form.name}
                        </label>
                        <input
                          type="text"
                          id="name"
                          required
                          name="name"
                          className="w-full px-4 py-3 bg-[#36383c] border border-[#36383c] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-laser focus:border-transparent"
                          placeholder={t.contact.form.namePlaceholder}
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-400 mb-2">
                          {t.contact.form.email}
                        </label>
                        <input
                          type="email"
                          id="email"
                          required
                          name="email"
                          className="w-full px-4 py-3 bg-[#36383c] border border-[#36383c] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-laser focus:border-transparent"
                          placeholder={t.contact.form.emailPlaceholder}
                        />
                      </div>
                      <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-400 mb-2">
                          {t.contact.form.message}
                        </label>
                        <textarea
                          id="message"
                          required
                          name="message"
                          rows="4"
                          className="w-full px-4 py-3 bg-[#36383c] border border-[#36383c] rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-laser focus:border-transparent"
                          placeholder={t.contact.form.messagePlaceholder}
                        ></textarea>
                      </div>
                      {/* Honeypot field - hidden from real users */}
                      <div className="absolute opacity-0 pointer-events-none">
                        <input
                          type="text"
                          name="botcheck"
                          tabIndex={-1}
                          autoComplete="off"
                          aria-hidden="true"
                          className="hidden"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`w-full px-6 py-3 bg-gradient-to-r from-laser to-[#FF8A3C] text-white rounded-lg font-medium hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] transition-all duration-300 ${
                          isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        {isSubmitting ? 'Sending...' : t.contact.form.submit}
                      </button>
                      
                    </form>
                    {result && (
                      <div className={`mt-4 p-4 rounded-lg ${
                        result.includes("Successfully") 
                          ? "bg-green-500/10 text-green-500" 
                          : "bg-red-500/10 text-red-500"
                      }`}>
                        {result}
                      </div>
                    )}
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home; 